import React, { useEffect } from "react";
import "./HomePage.css";
import Main from "../../Components/Main/Main";
import Home from "../../Components/Home/Home";
import { ProductsData } from "../../Data/ProductsData";
import { HomeSlider } from "../../Data/SliderImages";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="background">
      <Home slides={HomeSlider}/>
      <Main images={ProductsData} />
    </div>
  );
}

export default HomePage;