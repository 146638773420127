import HomePage from "./Pages/HomePage/HomePage";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import { Routes, Route } from "react-router-dom";
import NotFound from "./Pages/NotFound/NotFound";
import ProductsDetails from "./Pages/ProductsDetails/ProductsDetails";
import Layout from "./Components/Layout";
import { ProductsData } from "./Data/ProductsData";



function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout >
            <HomePage />
          </Layout>
        }
      />
      <Route
        path="/AboutUs"
        element={
          <Layout >
            <AboutUs />
          </Layout>
        }
      />
      <Route
        path="/ContactUs"
        element={
          <Layout >
            <ContactUs />
          </Layout>
        }
      />
      <Route path="*" element={<NotFound />} />
      <Route
        path="/Product/:ProductId"
        element={
          <Layout >
              <ProductsDetails images={ProductsData} />
              </Layout>
        }
      />
    </Routes>
  );
}

export default App;


  /*
    <BrowserRouter>
      <Navbar />
      <Home />

      <Main images={cars} />
      {

      <SwiperImage images={cars} />
      <ProductDetails />
      
 }

      <Footer />
    </BrowserRouter>
*/



/*
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <NotFound />,
  },
  {
    path: "/AboutUs",
    element: <AboutUs />,
  },
  {
    path: "/ContactUs",
    element: <ContactUs />,
  },
  {
    path: "/Product/:ProductId",
    element: <SwiperImage images={cars} />,
  },
]);
*/
