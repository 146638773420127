import React, { useEffect } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import "./Main.css";


function Main({ images }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="BG" dir="rtl">
      <section id="main" className="main container section">
        <div className="secTitle">
          <h3 data-aos="fade-right" data-aos-duration="1200" className="title">
           { /*Take A Look At Our Products*/}
ألق نظرة على منتجاتنا :
          </h3>
        </div>
        <div className="secContent grid">
            {images.map((image, index) => {
              return (
              <Link key={index} to={`/Product/${index}`}>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  key={index}
                  className="singleProduct"
                >
                  <div className="imageDiv">
                  <img src={image.Images[0]} alt={image.title}></img>
                  </div>
                  <div className="cardInfo">
                    <h4 className="productTitle">{image.title}</h4>
                    <div className="desc">
                      <p>{image.description}</p>
                    </div>
                  </div>
                </div>
              </Link>
              );
            })}
        </div>
      </section>
    </section>
  );
}

export default Main;
/*
const { data, isLoading, isError, error } = getAllProducts();
  
if (isLoading) {
  return <div>Loading...</div>;
}

if (isError) {
  return <div>Error: {error.message}</div>;
}


return (
  <div>
  {data.map(item => (
    <p key={item.id}>{item.name}</p>
  ))}
</div>
);

*/






/*
function Main({ images }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section id="main" className="main container section">
      <div className="secTitle">
        <h3 data-aos="fade-right" data-aos-duration="1200" className="title">
          Take A Look At Our Products
        </h3>
      </div>

      <div className="secContent grid">
        {images.map((image, index) => {
          return (
            <Link key={index} to={`/Product/${index}`} >
              <div
                data-aos="fade-up"
                data-aos-duration="1200"
                key={index}
                className={"singleProduct"}
              >
                <div className="imageDiv">
                  <img src={image.image} alt={image.title}></img>
                </div>
                
                <div className="cardInfo">
                  <h4 className="productTitle">{image.title}</h4>
                  <div className="desc">
                    <p>{image.description}</p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>

    </section>
  );
}

export default Main;
*/