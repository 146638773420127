import "./ContactUs.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contact-us" data-aos="fade-up" data-aos-duration="1500" dir="rtl">
      <h1 data-aos="fade-up" data-aos-duration="1500">
        تواصل معنا
      </h1>
      <hr className="solid" data-aos="fade-up" data-aos-duration="1500"></hr>

      <h2 data-aos="fade-up" data-aos-duration="1500">
        الاستفسارات العامة
      </h2>
      <ul>
        <li data-aos="fade-up" data-aos-duration="1500">
          الإيميل: info@biscoza.co
        </li>
        <li data-aos="fade-up" data-aos-duration="1500">
         رباعي: 4328
        </li>
        <li data-aos="fade-up" data-aos-duration="1500">
        هاتف: 963944024343+
        </li>
      </ul>

      <h2 data-aos="fade-up" data-aos-duration="1500">
        خدمة الزبائن
      </h2>
      <ul>
        <li data-aos="fade-up" data-aos-duration="1500">
        إيميل: info@biscoza.co
        </li>

      </ul>

      <h2 data-aos="fade-up" data-aos-duration="1500">
        الموقع
      </h2>
      <ul>
        <li data-aos="fade-up" data-aos-duration="1500">
ريف دمشق داريا القبلية خلف التاون سنتر
        </li>
        <li data-aos="fade-up" data-aos-duration="1500">
        </li>

      </ul>
      <h2 data-aos="fade-up" data-aos-duration="1500">
        مواقع التواصل
      </h2>
      <ul>
      <li data-aos="fade-up" data-aos-duration="1500">
          واتساب: 963944024343+
        </li>
        <li data-aos="fade-up" data-aos-duration="1500">
          فيسبوك: Biscoza
        </li>
        <li data-aos="fade-up" data-aos-duration="1500">
        إنستغرام: bisco.za
        </li>
        
      </ul>
      <h3 data-aos="fade-up" data-aos-duration="1500">
        شكراً لتواصلكم معنا، نتطلع لتلقي رسائلكم
      </h3>
    </section>
  );
}

export default ContactUs;
