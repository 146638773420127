export const HomeSlider = [
  {
    image: "/images/HomeSlider/HisteriaMaxBG.jpg",
    title: "Histeria Max",
    subtitle: "Choco & Biscuits",
  },
  {
    image: "/images/HomeSlider/HugyMooshDarkBG.jpg",
    title: "Hugy Choco",
    subtitle: "Chocolate Cake",
  },
  {
    image: "/images/HomeSlider/HugyMooshWhiteBG.jpg",
    title: "Hugy Milk",
    subtitle: "Vanilla Cake",
  },
  
];
