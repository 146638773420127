import React from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Loading/Loading.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ProductsDetails.css";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";



function ProductsDetails({ images }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  
  const { ProductId } = useParams();

  return (
    <>
    <div  className="hugy-body" dir="ltr">
      
      <div
      className="border-container"
      data-aos="fade-left"
      data-aos-duration="1000"
    >
      <h1 data-aos="fade-up" data-aos-duration="2700" className="gg">
      {images[ProductId].title}
      </h1>
      <Swiper
  className="pd-swiper"
  effect={"coverflow"}
  grabCursor={true}
  centeredSlides={true}
  slidesPerView={"auto"}
  coverflowEffect={{
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: false,
  }}
  navigation={true}
  autoplay={{
    delay: 3000,
  }}
  rewind={true}
  spaceBetween={10}
  pagination={{
    clickable: true,
    type: "bullets",
  }}
  modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
>
  {/* Display all images except the first */}
  {images[ProductId].Images.slice(1).map((image, index) => {
    return (
      <SwiperSlide key={index}  className="pd-swiper-slide">
        <img src={image} alt={`Product Image ${index + 1}`} />
      </SwiperSlide>
    );
  })}
</Swiper>
      <div className="background-hugy" dir="rtl">
      <section className="products-details-section">
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Product Description*/}
          وصف المنتج
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
           {images[ProductId].description}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Product ingredients*/}
          مكونات المنتج
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {images[ProductId].ingredients}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Weight*/}
          الوزن
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {images[ProductId].weight}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Packing*/}
التعبئة
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {images[ProductId].packing}
        </p>
      </section>
      </div>
    </div>
 
    </div>
   </>
  );
}

export default ProductsDetails;
