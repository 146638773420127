export const ProductsData = [
  {
    Images: [
      "/images/Product1/HisteriaMaxEaten.png",
      "/images/Product1/HisteriaMax1.png",
      "/images/Product1/HisteriaMax2.png",
      "/images/Product1/HisteriaMax3.png",
    ],

    title: "Histeria Max",
    description: "بسكويت مع الشوكولا المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة - دقيق القمح - جلوكوز - بودرة الكاكاو - كتلة الكاكاو - حليب خالي الدسم - حليب كامل الدسم - مصل الحليب - ديكستروز - ليستين الصويا E322 (مستحلب) - مواد رافعة (بيروفوسفات الصوديوم E450-1 - بيكربونات الأمونيوم - بيكربونات الصوديوم) - ملح الطعام - منكهات مماثلة للطبيعة.",
    weight: "30 غرام",
    packing: "12 قطعة / علبة",
  },

  {
    Images: [
      "/images/Product2/HugyMooshDarkEaten.png",
      "/images/Product2/HugyMooshDark1.png" ,
      "/images/Product2/HugyMooshDark2.png",
     "/images/Product2/HugyMooshDark3.png",

    ],
    title: "Hugy Moosh Chocolate",
    description: "كيك الشوكولا مع المارشميللو المغطس بالشوكولا",
    ingredients:"سكر - زيوت نباتية مهدرجة – مياه – دقيق الطحين – جلوكوز – كاكاو طبيعي – كتلة الكاكاو – بيض - حليب خالي الدسم - حليب كامل الدسم - مصل الحليب – سوربيتول – جيلاتين بقري – نشاء معدل – ديكستروز – ليستين الصويا E322 – غلسرين – كيك جيل – بيروفوسفات الصوديوم – بيكربونات الصوديوم – ملح الطعام – نكهة فانيل – نكهة حليب – نكهة الزبدة – سوربات بوتاسيوم – بروبلين جليكول.",
    weight: "30 غرام",
    packing: "12 قطعة / علبة",
  },

  {
    Images: [
      "/images/Product3/HugyMooshWhiteEaten.png",
      "/images/Product3/HugyMooshWhite1.png",
      "/images/Product3/HugyMooshWhite2.png",
      "/images/Product3/HugyMooshWhite3.png",
    ],
    title: "Hugy Moosh Milk",
    description: "كيك الحليب مع المارشميللو المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة – مياه – دقيق الطحين – جلوكوز – كاكاو طبيعي – كتلة الكاكاو – بيض - حليب خالي الدسم - حليب كامل الدسم - مصل الحليب – سوربيتول – جيلاتين بقري – نشاء معدل – ديكستروز – ليستين الصويا E322 – غلسرين – كيك جيل – بيروفوسفات الصوديوم – بيكربونات الصوديوم – ملح الطعام – نكهة فانيل – نكهة حليب – نكهة الزبدة – سوربات بوتاسيوم – بروبلين جليكول.",
    weight: "30 غرام",
    packing: "12 قطعة / علبة",
  },
];
